<template>
    <div class="groupOperation">
        <el-dialog
                :before-close="handleClose"
                :close-on-click-modal="false"
                :title="title"
                :visible.sync="dialogVisible"
                append-to-body
                width="800px">
            <div>
                <el-button  @click="add" type="primary">新增</el-button>
                <el-table :data="tableData" border style="width: 100%; margin-top: 20px" v-loading="loading" height="400"  row-key="couponGroupId">

                    <el-table-column align="center" label="优惠劵名称" prop="couponName">
                    </el-table-column>
                    <!--					<el-table-column prop="specDesc" label="尺码规格" align="center">-->
                    <!--					</el-table-column>-->
                    <el-table-column align="center" label="优惠劵链接" prop="couponLink">
                    </el-table-column>



                    <el-table-column align="center" label="优惠价格" prop="couponPrice">
                    </el-table-column>

                    <el-table-column align="center" label="操作">
                        <template slot-scope="scope">
                            <div class="operation">
                                <div class="space">
                                    <el-link @click="logic(scope.row)" type="primary"
                                             style="margin-right: 15px">
                                        删除
                                    </el-link>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <sb-pagination
                        :total="total"
                        @handleCurrentChange="handleCurrentChange"
                        @handleSizeChange="handleSizeChange">
                </sb-pagination>
            </div>

            <span class="dialog-footer" slot="footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button  @click="stop" type="primary">确 定</el-button>
			</span>
        </el-dialog>
        <coupon-model ref="couponModel" @successHandel="successHandel">
        </coupon-model>

    </div>
</template>

<script>
    // beforeCreate created beforeMount mounted 都不会触发。

    import sbPagination from '../../components/pagination/pagination.vue';

    import {COUPON,COUPONLOGIC} from "../../api/base";
    import CouponModel from './couponModel.vue'

    export default {
        data() {
            return {
                tableData: [],
                dialogVisible:false,
                loading:false,
                total: 0,
                form: {
                    pageIndex: 1,
                    pageSize: 10,
                    couponGroupId:'',
                    shopId:'',
                    couponGroupName:'',

                }
            }
        },

        props: {
            title: {
                type: String,
                default: () => {
                    return ''
                }
            },
        },
        methods: {
            add(){
                this.$refs.couponModel.open(this.form.couponGroupId,this.form.couponGroupName,this.form.shopId)
            },
            successHandel(){
                this.getList()
            },
            logic({couponId }){
                this.$confirm('此操作将永久删除该优惠劵, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios.delete(`${COUPONLOGIC}/${couponId}`).then(resp => {
                        if (resp.code == 200) {
                            this.getList()
                            this.$mes({message: resp.msg})
                        }
                    })
                }).catch(() => {
                    this.$mes({
                        type: 'info',
                        message: '已取消删除'
                    })
                });
            },
            handleCurrentChange() {

            },
            handleSizeChange(value) {
                this.form.pageIndex = value;
                this.getList()
            },
            handleClose() {
                this.dialogVisible = false;
                this.$emit('handleClose');
            },
            getList() {
                this.loading = true;
                this.$axios.get(COUPON, this.form).then(resp => {
                    this.loading = false;
                    if (resp.code == 200) {
                        this.tableData = resp.data.data;
                        this.total = resp.data.totalRow;

                    }
                })
            },
            open(row) {
                this.dialogVisible = true;
                this.form.couponGroupId = row.couponGroupId;
                this.form.shopId = row.shopId;
                this.form.couponGroupName = row.groupName;

                this.form.pageIndex = 1;
                this.form.pageSize = 10;

                this.loading = false;
                this.getList()

            },
            stop() {
                this.dialogVisible = false;
            }
        },
        components: {
            CouponModel,
            sbPagination
        },
        mounted() {

        }

    }
</script>

<style lang="less" scoped>
    .groupOperation /deep/ .is-checked {
        color: @primaryColorQian;
    }

    .groupOperation .specFlag {
        margin-left: 15px;
    }

    /deep/ .el-select .el-input.is-focus .el-input__inner {
        border-color: @primaryColorQian;
    }

    /deep/ .el-input__inner:focus {
        border-color: @primaryColorQian;
    }
</style>
