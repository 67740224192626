<template>
	<div class="groupOperation">
		<el-dialog
				:before-close="handleClose"
				:close-on-click-modal="false"
				:title="title"
				:visible.sync="dialogVisible"
				width="600px">
			<div>
				<el-form :model="form" :rules="formRules" label-width="130px" ref="form1" size="medium">

					<el-form-item label="所属店铺：" prop="shopId">
						<el-select placeholder="请选择店铺" style="width: 300px;" v-model="form.shopId">
							<el-option
									:key="index"
									:label="item.shopName"
									:value="item.shopId"
									v-for="(item,index) in shopList"
							>
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="分组名称：" prop="groupName">
						<el-input placeholder="请输入优惠劵分组名称" style="width: 300px;" v-model="form.groupName"></el-input>
					</el-form-item>

					<el-form-item label="分组类型：" prop="groupType">
						<el-switch
								:active-value="1"
								:inactive-value="0"
								active-text="一次性"
								inactive-text="固定"
								v-model="form.groupType">
						</el-switch>
					</el-form-item>

				</el-form>
			</div>

			<span class="dialog-footer" slot="footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button :loading="loading" @click="save" type="primary">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	// beforeCreate created beforeMount mounted 都不会触发。
	import {SHOPSELECT, COUPONGROUP} from "../../api/base";

	export default {
		data() {
			return {
				shopList: [],
				dialogVisible: false,
				loading: false,
				form: {
					shopId: '',
					couponGroupId: '',
					groupType: 1,
					groupName: "",

				},
				formRules: {
					shopId: [{
						required: true,
						message: '请选择所属店铺',
						trigger: 'change'
					}],
					groupType: [{
						required: true,
						message: '请选择分组类型',
						trigger: 'change'
					}],

					groupName: [{
						required: true,
						message: '请输入分组名称',
						trigger: 'blur'
					}],

				}
			}
		},
		props: {
			title: {
				type: String,
				default: () => {
					return ''
				}
			},
		},
		methods: {


			save() {
				this.$refs.form1.validate((validate) => {
					// 验证通过
					if (validate) {
						this.loading = true;
						if (this.title == '添加优惠劵分组') {
							this.$axios.post(COUPONGROUP, this.form).then(resp => {
								this.loading = false;
								if (resp.code == 200) {
									this.$mes({message: resp.msg})
									this.dialogVisible = false;
									this.$emit('successHandel')
								}
							})
						} else {
							this.$axios.put(`${COUPONGROUP}/${this.form.couponGroupId}`, this.form).then(resp => {
								this.loading = false;
								if (resp.code == 200) {
									this.$mes({message: resp.msg});
									this.dialogVisible = false;
									this.$emit('successHandel')

								}
							})
						}

					}
				})
			},
			handleClose() {
				this.dialogVisible = false;
				this.$emit('handleClose');
			},

			getShopList() {
				this.$axios.get(SHOPSELECT).then(resp => {
					console.log(resp)
					if (resp.code == 200) {
						this.shopList = resp.data;
					}
				})
			},
			open(row) {
				this.dialogVisible = true;
				this.loading = false;
				this.getShopList();
				this.$nextTick(() => {
					if (row) {
						this.$refs.form1.resetFields()
						this.$refs.form1.clearValidate()

						Object.keys(row).forEach(item => {
							if (this.form[item] != undefined) {
								this.form[item] = row[item]
							}
						});

						this.$refs.form1.clearValidate()
					} else {
						this.$refs.form1.resetFields()
					}
				})

			},
			stop() {
				this.dialogVisible = false;
			}
		},
		components: {},
		watch: {
			shopList: {
				handler(newShopList) {
					this.$emit('shopChange', newShopList)
				},
				immediate: true,
				deep: true,
			}
		},
		filters: {},

		mounted() {

		}

	}
</script>

<style lang="less" scoped>
	.groupOperation /deep/ .is-checked {
		color: @primaryColorQian;
	}

	.groupOperation .specFlag {
		margin-left: 15px;
	}

	/deep/ .el-select .el-input.is-focus .el-input__inner {
		border-color: @primaryColorQian;
	}

	/deep/ .el-input__inner:focus {
		border-color: @primaryColorQian;
	}
</style>
