<template>
    <div>
        <el-card class="box-card" style="width: 900px">
            <div class="clearfix" slot="header">
                <span>优惠劵管理</span>
            </div>
            <div class="addModel">
                <el-select placeholder="请选择店铺" style="width: 200px;margin-right: 15px" v-model="form.shopId" clearable filterable>
                    <el-option
                            :key="index"
                            :label="item.shopName"
                            :value="String(item.shopId)"
                            v-for="(item,index) in shopList"
                    >
                    </el-option>
                </el-select>
                <el-button @click="check" type="primary">查询</el-button>

                <el-button @click="addCouponGroup" type="primary"><i class="el-icon-plus"></i>添加优惠劵分组</el-button>
            </div>
            <div class="goodscard">
                <el-table :data="tableData" border style="width: 100%; margin-top: 20px" v-loading="loading" row-key="couponId">

                    <el-table-column align="center" label="店铺名称" prop="shopName">
                    </el-table-column>
                    <!--					<el-table-column prop="specDesc" label="尺码规格" align="center">-->
                    <!--					</el-table-column>-->
                    <el-table-column align="center" label="分组名称" prop="groupName">
                    </el-table-column>



                    <el-table-column align="center" label="类型" prop="groupType">
                        <template slot-scope="scope">
                            {{scope.row.groupType?'固定':'一次性'}}
                        </template>
                    </el-table-column>

                    <el-table-column align="center" label="剩余数量" prop="amount">
                    </el-table-column>

                    <el-table-column align="center" label="操作">
                        <template slot-scope="scope">
                            <div class="operation">
                                <div class="space">
                                    <el-link @click="editCouponGroup(scope.row)" type="primary"
                                             style="margin-right: 15px">
                                        修改
                                    </el-link>
                                    <el-link @click="deleteCouponGroup(scope.row)" type="primary"
                                             style="margin-right: 15px">
                                        删除
                                    </el-link>
<!--                                    <el-link @click="editCouponGroup(scope.row)" type="primary"-->
<!--                                             style="margin-right: 15px">-->
<!--                                        分组管理-->
<!--                                    </el-link>-->

                                    <el-link @click="addCoupon(scope.row)" type="primary">
                                        添劵
                                    </el-link>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <sb-pagination
                        :total="total"
                        @handleCurrentChange="handleCurrentChange"
                        @handleSizeChange="handleSizeChange">
                </sb-pagination>
            </div>
        </el-card>
        <group-model :title="title" @successHandel="successHandel" ref="groupModel"
                     @shopChange="shopChange">
        </group-model>
        <coupon-model :title="title" @successHandel="successHandel" ref="couponModel">
        </coupon-model>
    </div>
</template>

<script>
    import {COUPONGROUP, COUPONGROUPLOGIC,SHOPSELECT} from '../../api/base'
    import CouponModel from './coupon.vue'
    import GroupModel from './groupModel.vue'
    import sbPagination from '../../components/pagination/pagination.vue';


    export default {
        name: "coupon",
        components: {
            GroupModel,
            sbPagination,
            CouponModel,
        },
        data() {
            return {
                tableData: [],
                shopList: [],
                loading: false,
                total: 0,
                title: '',
                form: {
                    pageIndex: 1,
                    pageSize: 10,
                    shopId: '',
                }
            }
        },
        mounted() {
            this.getList();
            this.getShopList();
            this.form.shopId = this.$route.query?.shopId;
        },
        watch: {},
        methods: {
            shopChange(newShopList) {
                this.shopList = newShopList;

            },
            check() {
                this.form.pageIndex = 1;
                this.getList()
            },
            getShopList() {
                this.$axios.get(SHOPSELECT).then(resp => {
                    console.log(resp)
                    if (resp.code == 200) {
                        this.shopList = resp.data;
                    }
                })
            },
            deleteCouponGroup({couponGroupId}){
                this.$confirm('此操作将永久删除该分组, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios.delete(`${COUPONGROUPLOGIC}/${couponGroupId}`).then(resp => {
                        if (resp.code == 200) {
                            this.getList()
                            this.$mes({message: resp.msg})
                        }
                    })
                }).catch(() => {
                    this.$mes({
                        type: 'info',
                        message: '已取消删除'
                    })
                });

            },
            addCoupon(row){
                this.title = '剩余优惠劵';
                this.$refs.couponModel.open(row)
            },
            addCouponGroup() {
                this.title = '添加优惠劵分组';
                this.$refs.groupModel.open()
            },
            editCouponGroup(row) {
                this.title = '编辑优惠劵分组'
                this.$refs.groupModel.open(row)

            },
            getList() {
                this.loading = true;
                this.$axios.get(COUPONGROUP, this.form).then(resp => {
                    this.loading = false;
                    if (resp.code == 200) {
                        this.tableData = resp.data.data;
                        this.total = resp.data.totalRow;

                    }
                })
            },
            handleCurrentChange() {

            },
            handleSizeChange(value) {
                this.form.pageIndex = value;
                this.getList()
            },
            successHandel() {
                this.getList()
            },
        },
    }
</script>

<style scoped>

</style>